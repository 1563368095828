body {
  background-color: #000;
}
.m_modal {
  width: 420px !important;
}
.m_modal .ant-modal-content,
.m_modal .ant-modal-header {
  border-radius: .5rem;
}
.ant-checkbox-wrapper {
  margin-bottom: .5rem;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}